import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const SolutionHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.paper,
}));

const TagsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  marginTop: theme.spacing(2),
}));

const ContentSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  '& h2': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  '& h3': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  '& ul, & ol': {
    marginBottom: theme.spacing(2),
  },
}));

export const SolutionPostTemplate = ({
  content,
  description,
  tags,
  title,
  category,
  featuredimage,
}) => {
  return (
    <Box>
      <SolutionHeader>
        <Container maxWidth="lg">
          {featuredimage && (
            <Box sx={{ mb: 4, maxWidth: 800, mx: 'auto' }}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredimage,
                  alt: `featured image for ${title}`,
                }}
              />
            </Box>
          )}
          <Typography variant="h2" component="h1" gutterBottom align="center">
            {title}
          </Typography>
          {category && (
            <Typography variant="h5" color="primary" align="center" gutterBottom>
              {category}
            </Typography>
          )}
          {description && (
            <Typography variant="subtitle1" align="center" paragraph>
              {description}
            </Typography>
          )}
          {tags && tags.length > 0 && (
            <TagsContainer>
              {tags.map((tag) => (
                <Chip key={tag} label={tag} variant="outlined" />
              ))}
            </TagsContainer>
          )}
        </Container>
      </SolutionHeader>
      <ContentSection>
        <Container maxWidth="lg">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Container>
      </ContentSection>
    </Box>
  );
};

SolutionPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tags: PropTypes.array,
};

const SolutionPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SolutionPostTemplate
        content={post.html}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        category={post.frontmatter.category}
        featuredimage={post.frontmatter.featuredimage}
      />
    </Layout>
  );
};

SolutionPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default SolutionPost;

export const pageQuery = graphql`
  query SolutionPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        category
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;
